import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <SEO
      title="Privacy-Policy"
      keywords={[
        `gatsby`,
        `application`,
        `react`,
        `cabracabs`,
        `blockchaincabs`,
        `taxi`,
        `uk taxi`,
        `bkcc`,
      ]}
    />
    <h1>Privacy</h1>
  </Layout>
)

export default PrivacyPage
