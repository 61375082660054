import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styles from "./header.module.scss"
import logo from "../../images/Logo1.svg"
const Header = ({ siteTitle }) => {
  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <img src={logo} alt="cabraCabs" />
      </div>
      {/* <nav className={styles.navbar}>
        <ul className={styles.navList}>
          <li>Download App</li>
          <li>About us</li>
          <li>Support</li>
        </ul>
      </nav> */}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
