import * as React from "react"
import styles from "./footer.module.scss"
import { Link } from "gatsby"

const Footer = props => {
  return (
    <section className={styles.footer}>
      <ul>
        <li>
          <Link to="">Terms and Conditions</Link>
        </li>
        <li>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="">Contact us</Link>
        </li>
      </ul>
      <label>
        &copy; copyright {new Date().getFullYear()} CabraCabs Pvt. Ltd. All
        rights reserved.
      </label>
    </section>
  )
}

export default Footer
